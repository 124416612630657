.mpc-container {
  font-family: Montserrat; }
  .mpc-container .weekly-schedule .day {
    display: flex; }
    .mpc-container .weekly-schedule .day .day-checkbox {
      width: 160px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500; }
    .mpc-container .weekly-schedule .day .day-timings {
      margin-bottom: 10px; }
      .mpc-container .weekly-schedule .day .day-timings .rows .time {
        display: flex; }
        .mpc-container .weekly-schedule .day .day-timings .rows .time .time-container {
          margin-left: 30px;
          width: 150px; }
          .mpc-container .weekly-schedule .day .day-timings .rows .time .time-container .time-text {
            font-family: Montserrat;
            font-size: 10px;
            margin-bottom: -1px; }
        .mpc-container .weekly-schedule .day .day-timings .rows .time .remove-slot {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 25px;
          padding-top: 15px; }
      .mpc-container .weekly-schedule .day .day-timings .add-more {
        font-family: "Varela Round", sans-serif;
        cursor: pointer;
        color: #f97f0f;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        justify-content: flex-start;
        margin-left: 390px; }
  .mpc-container .select-time {
    margin: 0px;
    width: 100%;
    font-size: small;
    font-family: Montserrat; }
  .mpc-container .heading-and-add-btn {
    display: flex;
    justify-content: space-between; }
    .mpc-container .heading-and-add-btn .btn-enabled {
      margin-left: 10px;
      min-width: 50px;
      height: 25px;
      padding-top: 6px;
      text-transform: none;
      font-size: small;
      border-radius: 5px;
      color: #FFFFFF;
      background-color: #F77F11; }
    .mpc-container .heading-and-add-btn .btn-disabled {
      margin-left: 10px;
      min-width: 50px;
      height: 25px;
      padding-top: 6px;
      text-transform: none;
      font-size: small;
      border-radius: 5px;
      color: #FFFFFF;
      background-color: #EBEBEB;
      box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }
  .mpc-container .column-heading-text {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px; }
  .mpc-container .column-heading-filter-text {
    font-family: Montserrat;
    font-size: 10px; }
  .mpc-container .leave-row {
    padding: 3px;
    border-bottom: 1px solid #D8D8D8;
    font-family: Montserrat;
    font-size: 11px; }
  .mpc-container .btn-enabled {
    margin-left: 10px;
    min-width: 100px;
    height: 25px;
    padding-top: 6px;
    text-transform: none;
    font-size: small;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #F77F11; }
  .mpc-container .btn-disabled {
    margin-left: 10px;
    min-width: 100px;
    height: 25px;
    padding-top: 6px;
    text-transform: none;
    font-size: small;
    border-radius: 5px;
    color: #000000;
    background-color: #EBEBEB;
    box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }
  .mpc-container .calendar-add-on-container .separate-fields-to {
    padding: 10px;
    margin: 0px 15px 0px 15px; }
  .mpc-container .time-addon {
    display: flex; }
    .mpc-container .time-addon .time-container {
      width: 200px; }
      .mpc-container .time-addon .time-container .time-text {
        font-family: Montserrat;
        font-size: 10px;
        margin-bottom: -1px; }
    .mpc-container .time-addon .remove-slot {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 25px;
      padding-top: 15px; }
    .mpc-container .time-addon .add-more {
      margin-top: 26px;
      font-family: "Varela Round", sans-serif;
      cursor: pointer;
      color: #f97f0f;
      font-size: 12px;
      font-weight: 700;
      display: flex;
      justify-content: flex-end; }
  .mpc-container .calendar-field-container {
    display: flex;
    flex-direction: column; }
  .mpc-container .calendar-field-container-comment {
    display: flex;
    flex-direction: column;
    width: 300px; }
  .mpc-container .calendar-label-text {
    font-family: Montserrat;
    font-size: 10px;
    margin-bottom: -1px; }
  .mpc-container .calendar-backdrop-class {
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 45;
    width: 100vw;
    height: 100vh; }
  .mpc-container .calendar-filter-popup {
    position: relative;
    bottom: -5px;
    right: 36%;
    transform: translateX(20%);
    z-index: 999;
    background-color: white;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #d8d8d8;
    display: flex;
    align-items: flex-end; }
    .mpc-container .calendar-filter-popup .calendar-filter-popup-filter-class {
      flex: 1; }
    .mpc-container .calendar-filter-popup .calendar-filter-popup-date-range-filter {
      flex: 1; }
  .mpc-container .pagination-container {
    display: flex;
    justify-content: flex-end;
    font-size: 12px; }
  .mpc-container .page-button-right {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 30px;
    height: 20px;
    transform: rotate(90deg); }
    .mpc-container .page-button-right .icon-enable {
      color: #ef7c01; }
    .mpc-container .page-button-right .icon-disable {
      color: #666666; }
  .mpc-container .page-button-left {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 30px;
    height: 20px;
    transform: rotate(270deg); }
    .mpc-container .page-button-left .icon-enable {
      color: #ef7c01; }
    .mpc-container .page-button-left .icon-disable {
      color: #666666; }
