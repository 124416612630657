/* App.css */
/* Container for the table */
.table-container {
  margin: 0 auto;
  margin-left: 30px;
  margin-right: 30px;
  font-family: Arial, sans-serif;
  overflow-x: auto;
  border-radius: 8px;
  border: 1px solid #ced4da; }

/* Table styles */
.expandable-table {
  width: 100%;
  border-collapse: collapse; }

/* Header and cell styling */
.expandable-table td {
  padding: 8px;
  text-align: left;
  white-space: wrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Ensures content doesn't overflow the cell */
  text-overflow: ellipsis;
  /* Adds ellipsis if text overflows */ }

.expandable-table th {
  background-color: #ced4da;
  padding: 8px;
  text-align: left;
  white-space: wrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Ensures content doesn't overflow the cell */
  text-overflow: ellipsis;
  /* Adds ellipsis if text overflows */ }

/* Specific column widths */
.expandable-table th:nth-child(1),
.expandable-table td:nth-child(1) {
  max-width: 30%;
  /* Name column width */ }

.expandable-table th:nth-child(2),
.expandable-table td:nth-child(2) {
  max-width: 20%;
  /* Age column width */ }

.expandable-table th:nth-child(3),
.expandable-table td:nth-child(3) {
  max-width: 30%;
  /* City column width */ }

.expandable-table th:nth-child(4),
.expandable-table td:nth-child(4) {
  max-width: 20%;
  /* This column will have the 'Details' content */ }

.table-row-border {
  border-top: 1px solid #c2c2c2; }

.expanded-row {
  background-color: #fafafa; }

.expanded-content {
  padding: 10px;
  font-size: 14px;
  color: #555; }
