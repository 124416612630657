.mp-bg-color {
  background-color: burlywood; }

.mp-bg-color2 {
  background-color: cornsilk; }

.services-card {
  margin: 1rem;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center; }

.services-icon-text-align-center {
  text-align: center; }

.services-icon {
  font-size: 5rem !important; }

.service-dashboard-container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.mp-page-padding {
  padding: 2rem 3rem 2rem 3rem; }

.mp-two-side-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.mp-buttons-container {
  display: flex;
  flex-direction: row; }

.mp-buttons-right-alignment {
  text-align: right; }

.mp-button {
  margin-left: 10px;
  min-width: 125px; }

.mp-button-save-enabled {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #F77F11; }

.mp-button-save-disabled {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.mp-button-cancel {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #333333;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.mp-sub-heading-margin {
  margin-top: 20px;
  margin-bottom: 20px; }

.mp-label {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: -6px; }

.mp-label-2 {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px; }

.mp-label-red {
  color: red; }

.mp-field-container {
  display: flex;
  flex-direction: column; }

.mp-select-field {
  width: 100%;
  font-size: larger;
  font-family: Montserrat; }

.mp-between-two-side {
  color: #666666;
  font-family: Montserrat;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.mp-master-availability-startTime-endTime {
  min-width: 100%; }
  .mp-master-availability-startTime-endTime::before {
    border-bottom: 1px solid #d8d8d8; }
  .mp-master-availability-startTime-endTime::after {
    border-bottom: 1px solid #d8d8d8; }
  .mp-master-availability-startTime-endTime:focus {
    background-color: transparent; }
  .mp-master-availability-startTime-endTime:focus-within {
    background-color: transparent; }
  .mp-master-availability-startTime-endTime::before :hover {
    border-bottom: 1px solid #d8d8d8; }

.mp-master-availability-section {
  display: flex;
  flex-direction: column; }
  .mp-master-availability-section .mp-master-availability-timing-container {
    display: grid;
    grid-template-columns: 200px 200px 200px;
    column-gap: 24px;
    margin-top: 12px; }
    .mp-master-availability-section .mp-master-availability-timing-container .mp-master-availability-timing-top {
      display: flex;
      align-items: center; }
      .mp-master-availability-section .mp-master-availability-timing-container .mp-master-availability-timing-top p {
        margin: 0 0 0 6px;
        color: #000;
        font-size: 14px;
        font-weight: 400; }
    .mp-master-availability-section .mp-master-availability-timing-container .mp-master-availability-timing-mid-bottom p {
      font-size: 12px;
      font-weight: 400;
      color: #333; }

.mp-button-arrow {
  color: white;
  margin-top: -20px; }

.mp-dashboard-table-headings-container {
  position: sticky;
  background-color: white;
  top: 65px;
  margin-top: 1em;
  box-shadow: 0px 3px 3px #D6D6D6;
  padding: 1em;
  display: flex;
  justify-content: space-between; }

.mp-dashboard-table-heading {
  font-size: 13px;
  color: #464646;
  font-weight: 700;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 1em;
  position: relative; }

.mp-column1 {
  padding: 2px;
  flex: .7 1 0%;
  overflow: hidden; }

.mp-column2 {
  padding: 2px;
  flex: 2 1 0%;
  overflow: hidden; }

.mp-column3 {
  padding: 2px;
  flex: 1 1 0%;
  overflow: hidden; }

.mp-column4 {
  padding: 2px;
  flex: 1 1 0%;
  overflow: hidden; }

.mp-column5 {
  padding: 2px;
  flex: 1.3 1 0%;
  overflow: hidden; }

.mp-column6 {
  padding: 2px;
  flex: 1 1 0%;
  overflow: hidden; }

.mp-column8 {
  padding: 2px;
  flex: 1 1 0%;
  overflow: hidden; }

.mp-column9 {
  padding: 2px;
  flex: .2 1 0%;
  overflow: hidden; }

.mp-filter-icon-container {
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
  border-radius: 2px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.mp-filter-icon {
  height: 11px; }

.mp-dashboard-table-row-container {
  box-shadow: 0px 1px 1px #D6D6D6;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  justify-content: space-between;
  max-height: 2em;
  padding-top: 5px; }

.mp-dashboard-table-row-container:hover {
  max-height: fit-content;
  background-color: #fbf7ff;
  border-radius: 5px; }

.mp-dashboard-table-cell {
  font-size: 13px;
  color: #464646;
  font-weight: 500;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  margin-right: 1em; }

.mp-dashboard-table-status-cell-active {
  background-color: #CAECDE;
  color: #0E5033;
  width: 6em;
  height: 1.5em;
  font-weight: 800;
  text-align: center;
  border-radius: 10px; }

.mp-dashboard-option-icon-container {
  margin-top: -2px;
  border-radius: 2px;
  height: 20px;
  width: 15px;
  display: flex;
  justify-content: center;
  padding: 2px;
  cursor: pointer; }

.mp-dashboard-option-icon {
  height: 15px; }

.mp-dashboard-filter-icon-btn:hover {
  background-color: transparent; }

.mp-dashboard-filter-applied-on-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }

.mp-dashboard-filter-applied-on-heading {
  font-size: 13px;
  color: #464646;
  font-weight: 600;
  font-family: Montserrat;
  margin-right: 1em; }

.mp-dashboard-filter-applied-on {
  border: 2px solid plum;
  border-radius: 5px;
  padding: 0px 5px;
  margin-right: 1em;
  font-size: 13px;
  color: #464646;
  font-weight: 500;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  align-items: center; }

.mp-dashboard-filter-applied-cross-icon-container {
  border-radius: 10px;
  background-color: #e0e0e0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  margin-left: 5px; }

.mp-dashboard-filter-applied-cross-icon:hover {
  background-color: #d4d4d4; }

.mp-dashboard-row-below-table-heading {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  margin-top: 1em; }

.mp-dashboard-pagination-container {
  display: flex;
  align-items: center; }

.mp-dashboard-created-on-filter-container {
  padding: 1em;
  display: flex; }

.mp-dashboard-menu-box {
  max-height: 70vh; }

.mp-create-service-container {
  width: 100%; }

.mp-create-service-field-container {
  display: flex;
  flex-direction: column; }

.mp-text-field-icon-container {
  margin-top: -10px;
  margin-right: -10px; }

.mp-table2-container {
  border: 1px solid #CED4DA;
  border-radius: 10px; }

.mp-table2-heading-container {
  box-shadow: 0px 2px 2px #D6D6D6;
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: space-between; }

.mp-table2-rows-container {
  padding: .3em 1em .3em 1em;
  width: 100%;
  display: flex;
  justify-content: space-between; }

.mp-table2-option-icon-container {
  height: 10px; }

.mp-heading-with-back-button {
  display: flex; }

.mp-age-checkbox {
  display: flex; }

.mp-slider-container {
  padding-top: 10px; }

.mp-age-text-container {
  margin-top: -10px;
  color: #666666;
  font-family: Montserrat;
  font-size: 12px; }

.mp-text-headings {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px; }

.mp-add-options {
  font-size: 11px;
  color: #F77F11;
  font-weight: 900;
  cursor: pointer; }

.mp-phone-container {
  display: flex;
  align-items: center; }

.mp-alt-phone-remove {
  cursor: pointer; }

.mp-email-container {
  display: flex;
  align-items: center; }

.mp-alt-email-remove {
  cursor: pointer; }

.mp-spoc-heading-container {
  display: flex;
  align-items: center; }

.mp-spoc-remove {
  padding-top: 20px;
  cursor: pointer; }

.mp-image-requirements {
  margin-top: 15px;
  color: #666666;
  font-family: Montserrat;
  font-size: 10px; }

.mp-button-upload {
  margin-top: 10px;
  width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #333333;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.mp-label-checkbox {
  margin-top: 14px;
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: -6px; }

.mp-checkbox-field-container {
  display: flex;
  flex-direction: column; }
