.heading-container {
  width: 100%;
  height: 100%;
  padding-left: 15px; }

.heading-inner-container {
  display: flex;
  margin-left: 20px;
  margin-top: 24px; }

.heading-back-arrow {
  cursor: pointer; }
  .heading-back-arrow i {
    margin-right: 16px;
    color: #ef7c01; }

.heading-text {
  font-size: 32px;
  line-height: 21px;
  font-weight: 700;
  color: #333; }

.outermost-div {
  font-family: "Montserrat" !important; }

.service-details-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "Montserrat" !important; }

table {
  font-family: "Montserrat" !important; }

.heading-h3 {
  font-size: 24px;
  line-height: 21px;
  font-weight: 700;
  color: #333;
  margin-left: 30px;
  margin-right: 30px; }

.navigo-appoint-non-select-status-inactive {
  background: #FBDFD9;
  color: #E44437;
  border-radius: 60px;
  padding: 2px 10px;
  width: fit-content;
  font-size: 14px;
  font-weight: 700; }

.navigo-appoint-non-select-status-active {
  background-color: #caecde;
  color: #0b5033;
  border-radius: 60px;
  padding: 2px 10px;
  width: fit-content;
  font-size: 14px;
  font-weight: 700; }

.service-provider-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 30px; }

.service-provider-header-inner-div {
  display: flex;
  flex-direction: row;
  gap: 15px; }

.expansion-list-content-class {
  padding: 0;
  margin: 0; }

.heading-outer-container {
  width: 100%; }

.heading-inner-container {
  width: 100%; }

.heading-inner-container-text-and-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 50px;
  width: 100%; }
