.mp-plan-page-padding {
  padding: 2rem 3rem 2rem 3rem; }

.mp-plan-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.mp-plan-heading-with-back-button {
  display: flex; }

.mp-plan-button-save-enabled {
  cursor: pointer;
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #F77F11; }

.mp-plan-button-save-disabled {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.mp-plan-buttons-container {
  display: flex;
  flex-direction: row; }

.mp-plan-buttons-right-alignment {
  text-align: right; }

.mp-plan-button-cancel {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #333333;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.mp-plan-create-service-container {
  width: 100%; }

.mp-plan-create-service-field-container {
  display: flex;
  flex-direction: column; }

.mp-plan-label {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: -6px; }

.mp-plan-label-checkbox {
  margin-top: 11px;
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: -6px; }

.mp-plan-label-red {
  color: red; }

.mp-plan-select-field {
  width: 100%;
  font-size: larger;
  font-family: Montserrat; }

.mp-plan-back-btn {
  cursor: pointer; }
  .mp-plan-back-btn i {
    margin-right: 12px;
    color: #ef7c01; }

.mp-plan-prefix {
  padding-top: 10px;
  color: #666666;
  font-family: Montserrat;
  margin-right: 4px; }

.mp-plan-domains-container {
  display: flex;
  flex-direction: row; }

.mp-plan-domains-box {
  display: flex;
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 2px 7px 2px 7px;
  margin: 2px 4px 2px 0px; }

.mp-plan-font {
  color: #666666;
  font-family: Montserrat;
  font-size: 13px; }

.mp-plan-image-requirements {
  margin-top: 15px;
  color: #666666;
  font-family: Montserrat;
  font-size: 10px; }

.mp-plan-button-upload {
  margin-top: 10px;
  width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #333333;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.mp-plan-grey-heading {
  color: #bdbdbd;
  font-size: 18px;
  font-weight: 800;
  font-family: Montserrat; }

.mp-plan-create-plan-container {
  width: 100%; }

.mp-plan-label-2 {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px; }

.mp-plan-box-unselected {
  width: fit-content;
  height: fit-content;
  display: flex;
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 2px 7px 2px 7px;
  margin: 2px 4px 2px 0px;
  cursor: pointer; }

.mp-plan-box-selected {
  width: fit-content;
  height: fit-content;
  display: flex;
  border: 2px solid #6A5DF5;
  border-radius: 10px;
  padding: 2px 7px 2px 7px;
  margin: 2px 4px 2px 0px;
  cursor: pointer; }

.mp-plan-multiple-box-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; }
