.account-module-page-padding {
  padding: 2rem 3rem 2rem 3rem; }

.account-module-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.account-module-heading-with-back-button {
  display: flex; }

.account-module-button-save-enabled {
  cursor: pointer;
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #F77F11; }

.account-module-button-save-disabled {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.account-module-buttons-container {
  display: flex;
  flex-direction: row; }

.account-module-buttons-right-alignment {
  text-align: right; }

.account-module-button-cancel {
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #333333;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }

.account-module-create-service-container {
  width: 100%; }

.account-module-create-service-field-container {
  display: flex;
  flex-direction: column; }

.account-module-label {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: -6px; }

.account-module-label-red {
  color: red; }

.account-module-select-field {
  width: 100%;
  font-size: larger;
  font-family: Montserrat; }

.account-module-back-btn {
  cursor: pointer; }
  .account-module-back-btn i {
    margin-right: 12px;
    color: #ef7c01; }

.account-module-prefix {
  padding-top: 10px;
  color: #666666;
  font-family: Montserrat;
  margin-right: 2px; }

.account-module-email-prefix {
  padding-top: 11px;
  color: #666666;
  font-family: Montserrat;
  font-size: 12px; }

.account-module-domains-container {
  display: flex;
  flex-direction: row; }

.account-module-domains-box {
  display: flex;
  border: 1px solid #979797;
  border-radius: 15px;
  padding: 2px 7px 2px 7px;
  margin: 2px 4px 2px 0px; }

.account-module-font {
  color: #666666;
  font-family: Montserrat;
  font-size: 13px; }

.account-module-image-requirements {
  margin-top: 15px;
  color: #666666;
  font-family: Montserrat;
  font-size: 10px; }

.account-module-button-upload {
  margin-top: 10px;
  width: 125px;
  text-transform: none;
  font-size: medium;
  border-radius: 5px;
  color: #333333;
  background-color: #EBEBEB;
  box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2); }
