/* App.css */
/* Container for the table */
.table-container-assigned-plans {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  overflow-x: auto;
  border-radius: 8px;
  border: 1px solid #c2c2c2 !important; }

/* Table styles */
.expandable-table-astp {
  width: 100%;
  border-collapse: collapse; }

/* Header and cell styling */
.expandable-table-astp td {
  padding: 8px;
  text-align: left;
  white-space: wrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Ensures content doesn't overflow the cell */
  text-overflow: ellipsis;
  /* Adds ellipsis if text overflows */ }

.expandable-table-astp th {
  background-color: white;
  border-bottom: 1px solid #c2c2c2;
  padding: 8px;
  text-align: left;
  white-space: wrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Ensures content doesn't overflow the cell */
  text-overflow: ellipsis;
  /* Adds ellipsis if text overflows */ }

/* Specific column widths */
.expandable-table-astp th:nth-child(1),
.expandable-table-astp td:nth-child(1) {
  max-width: 30%;
  /* Name column width */ }

.expandable-table-astp th:nth-child(2),
.expandable-table-astp td:nth-child(2) {
  max-width: 20%;
  /* Age column width */ }

.expandable-table-astp th:nth-child(3),
.expandable-table-astp td:nth-child(3) {
  max-width: 30%;
  /* City column width */ }

.expandable-table-astp th:nth-child(4),
.expandable-table-astp td:nth-child(4) {
  max-width: 20%;
  /* This column will have the 'Details' content */ }

.table-row-border {
  border-top: 1px solid #c2c2c2; }

.table-row {
  cursor: pointer;
  transition: background-color 0.3s ease; }

.table-row:hover {
  background-color: #f1f1f1; }

.expanded-row {
  background-color: #fafafa; }

.expanded-content {
  padding: 10px;
  font-size: 14px;
  color: #555; }

.filter-div-assigned-plans-table {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  z-index: 1000;
  position: absolute;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px; }

.filter-icon-assigned-plans:hover {
  cursor: pointer; }
