.mp-astp-first-div {
  font-family: Montserrat; }

.mp-assign-service-apply-new-rule-label-outer-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px; }
  .mp-assign-service-apply-new-rule-label-outer-div .mp-assign-service-apply-new-rule-label-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }

.mp-assign-service-apply-new-rule-label-outer-div:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  cursor: pointer;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px; }
  .mp-assign-service-apply-new-rule-label-outer-div:hover .mp-assign-service-apply-new-rule-label-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }

.mp-assigned-service-providers-label-outer-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid #c2c2c2;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px; }
  .mp-assigned-service-providers-label-outer-div .mp-assign-service-apply-new-rule-label-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }

.mp-assign-service-to-plan-family-relations-bubbles-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px; }

.mp-astp-rules-family-member-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px; }

.mp-astp-rules-outer-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px; }

.mp-plan-create-service-field-container-v2 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%; }

.mp-plan-label-v2 {
  display: flex;
  flex-direction: row;
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: -6px; }

.expansion-list-content-class-v2 {
  padding-left: 0 !important;
  /* Remove default padding */
  padding-right: 0 !important;
  /* Remove default padding */
  padding-bottom: 0 !important;
  /* Remove default padding */
  padding-top: 20px;
  background-color: white; }

.mp-row--modes-textfields-astp {
  margin-right: 20px;
  margin-left: 20px;
  background-color: white; }

.mp-row-modes-adv-rules-div-astp {
  background-color: white;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 40px; }

.mp-astp-rules-family-member-div {
  font-family: Montserrat;
  font-size: 12px; }

.mp-plan-button-add-new-rule-enabled-astp {
  font-family: Montserrat;
  font-size: 13px;
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  border-radius: 5px;
  color: #f77f11;
  background-color: #fff; }

.mp-plan-button-add-new-rule-enabled-astp:disabled {
  font-family: Montserrat;
  font-size: 13px;
  margin-left: 10px;
  min-width: 125px;
  text-transform: none;
  border-radius: 5px;
  color: #c2c2c2;
  background-color: #fff; }

.mp-add-rule-btn-and-arrow-font-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.mp-advance-rule-close-fonticon-astp:hover {
  cursor: pointer; }

.mp-astp-or-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #666666;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600; }

.mp-astp-expandable-tables-outer-div {
  padding: 16px; }
